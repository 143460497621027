


//NOOUSEW


//
// // menu onepage
// $(document).ready(function(){
// $('#menu ul li:first').addClass('active');
// $('.nav ul li:first').addClass('active');
//         $('section').waypoint(function(direction) {
//             //var activeSection = $(this).next();
//             var activeSection = $(this.element);
//             if(direction === 'down'){
//                 activeSection = $(this.element).next();
//             }
//             //activeSection = $(this);
//             var sectionId  = activeSection.data('nr');
//             $('#menu ul li').removeClass('active');
//             $('#menu ul li:nth-child(' + sectionId+')').addClass('active');
//             $('.nav ul li').removeClass('active');
//             $('.nav ul li:nth-child(' + sectionId+')').addClass('active');
//             //console.log(activeSection);
//         });
// });



$(window).scroll(function(){
  var scroll = $(window).scrollTop();
  if (scroll > 1){
    $('.top').addClass('active');
  }else{
    $('.top').removeClass('active');
  }
});



// menu home
$('a[href*=\\#]:not([href=\\#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
        || location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
           if (target.length) {
             $('html,body').animate({
                 scrollTop: target.offset().top - 110
            }, 500);
            return false;
        }
    }
});
// menu home
//open/close popup
$(document).on("click", ".pop-open", function() {
  $(".popup").toggle();
});
$(document).on("click", ".pop-close", function() {
  $(".popup").toggle();
});
//open/close popup
//same height elements
$(document).ready(function(){
$.fn.setAllToMaxHeight = function(){
return this.height( Math.max.apply(this, $.map( this , function(e){ return $(e).height() }) ) );
}
$('.sameheight-1').setAllToMaxHeight();
$('.sameheight-2').setAllToMaxHeight();
$('.sameheight-3').setAllToMaxHeight();
$('.sameheight-4').setAllToMaxHeight();
});
//same height elements
// language switcher dropdown
var selected = $('.drop-dl .current-lang img').attr('src');
$('.lang-chose .lng').attr('src', selected);
$('.lang-chose').click(function(event) {
    $('.drop-dl ul').slideToggle(500);
    event.preventDefault();
    return false;
});
$(document).bind('click', function(e) {
  var $clicked = $(e.target);
  if (! $clicked.parents().hasClass("drop-dl"))
  $(".drop-dl ul").hide();
});
// language switcher dropdown
// section 5 active for first
$('.change-box-5 ul li:first').addClass('active');
$('.list-5 .box').not('.box:first').fadeToggle();
// section 5 active for first
// section 5 change boxes
$(document).on("click", ".change-box-5 ul li", function() {
  $('.change-box-5 ul li').removeClass('active');
  $(this).addClass('active');
  box_number = $(this).attr('data-s8m');
  console.log(box_number);
  $('.list-5 .box').hide();
  $('.list-5 .box[data-s8="'+box_number+'"]').fadeToggle();
});
// section 5 change boxes
// section 7 active for first
$('.change-box ul li:first').addClass('active');
$('.list .box').not('.box:first').fadeToggle();
// section 7 active for first
// section 7 change boxes
$(document).on("click", ".change-box ul li", function() {
  $('.change-box ul li').removeClass('active');
  $(this).addClass('active');
  box_number = $(this).attr('data-s8m');
  console.log(box_number);
  $('.list .box').hide();
  $('.list .box[data-s8="'+box_number+'"]').fadeToggle();
});
// section 7 change boxes
// burger menu
$(".burger-menu").click(function () {
  $(this).toggleClass('actives');
  $(".mobile-menu").toggleClass('active');
});
$(".menu-item a").click(function () {
     $(".mobile-menu").removeClass('active');
     $(".burger-menu").removeClass('actives');
});
// burger menu
// Set cookies onload
function WHCreateCookie(c, d, e) {
    var b = new Date();
    b.setTime(b.getTime() + (e * 24 * 60 * 60 * 1000));
    var a = "; expires=" + b.toGMTString();
    document.cookie = c + "=" + d + a + "; path=/";
}
function WHReadCookie(b) {
    var e = b + "=";
    var a = document.cookie.split(";");
    for (var d = 0; d < a.length; d++) {
        var f = a[d];
        while (f.charAt(0) == " ") {
            f = f.substring(1, f.length);
        }
        if (f.indexOf(e) == 0) {
            return f.substring(e.length, f.length);
        }
    }
    return null;
}
window.onload = WHCheckCookies;
function WHCheckCookies() {
    if (WHReadCookie("cookies_accepted") !== "T") {
        $('#cookies-message').fadeToggle();
    }
}
function WHCloseCookiesWindow() {
    WHCreateCookie("cookies_accepted", "T", 365);
    $("#cookies-message").hide();
}
// Set cookis onload
